import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const PortfolioMaterials = React.lazy(() => import("../components/PortfolioMaterials"));
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const ServiceDetailsInner = React.lazy(() =>
  import("../components/ServiceDetailsInner")
);
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const ServiceDetails = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          <SearchPopup />
          <NavbarTwo />
          <Breadcrumb title={"MATERIALES DE CONSTRUCCION"} />
          <ServiceDetailsInner />
          <PortfolioMaterials />
          <FooterOne />
          <FooterBottomOne />
        </Suspense>
      </Fragment>
    </>
  );
};

export default ServiceDetails;
