import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarTwo = React.lazy(() => import("../components/NavbarTwo"));
const PartnerOne = React.lazy(() => import("../components/PartnerOne"));
const ServiceOneAll = React.lazy(() => import("../components/ServiceOneAll"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const Service = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          
          <SearchPopup />

          
          <NavbarTwo />

          
          <Breadcrumb title={"SERVICIOS"} />

          
          <ServiceOneAll />


         
          <PartnerOne />

          
          <FooterOne />

         
          <FooterBottomOne />
        </Suspense>{" "}
      </Fragment>
    </>
  );
};

export default Service;
